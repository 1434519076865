import Link from 'next/link';
import Image from 'next/image';
export function Logo() {
  return (
    <div className="logo">
      <Link href="https://lifeorganized.app">
        <a>
          <Image
            src="/images/logo@1x.png"
            alt="Organized logo"
            width="64"
            height="64"
          />
          <span>Life Organized</span>
        </a>
      </Link>
    </div>
  );
}

export default Logo;

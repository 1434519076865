import Link from 'next/link';
import Image from 'next/image';
import { URL } from '../config';
export function Footer() {
  return (
    <footer className="footer">
      <div>
        <h3>&copy; Life Organized</h3>
        <ul>
          <li>
            <Link href="/about-us">
              <a>About Us</a>
            </Link>
          </li>
          <li>
            <Link href="mailto:contact@lifeorganized.app">
              <a>Contact Us</a>
            </Link>
          </li>
          <li>
            <Link href="/privacy-and-terms">
              <a>Privacy &amp; terms</a>
            </Link>
          </li>
        </ul>
      </div>
      <div>
        <Link href={URL.APP_STORE}>
          <a>
            <Image
              src="/svg/appstore_black.svg"
              alt="Appstore logo"
              className="store_badge"
              width="128"
              height="42"
            />
          </a>
        </Link>

        <Link href={URL.PLAY_STORE}>
          <a>
            <Image
              src="/svg/googleplaystore.svg"
              alt="Playstore logo"
              className="store_badge"
              width="128"
              height="42"
            />
          </a>
        </Link>
      </div>
    </footer>
  );
}

export default Footer;

import React, { useEffect, useState } from 'react';

import Link from 'next/link';
import Logo from './Logo';
import { URL } from '../config';

function Header() {
  let listener = null;
  const [isScrolled, setScroll] = useState(false);

  useEffect(() => {
    listener = document.addEventListener('scroll', () => {
      const scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 120) {
        if (!isScrolled) {
          setScroll(true);
        }
      } else {
        if (isScrolled) {
          setScroll(false);
        }
      }
    });
    return () => {
      document.removeEventListener('scroll', listener);
    };
  }, [isScrolled]);
  return (
    <header className={`header ${isScrolled ? 'scrolled' : ''}`}>
      <Logo />
      <nav className="navigation">
        <Link href={URL.APP_STORE}>
          <a className="button">
            <svg width="14" height="16">
              <path
                d="M13.707 12.469a8.611 8.611 0 01-.894 1.546c-.47.645-.855 1.092-1.151 1.34-.46.407-.952.615-1.48.627-.378 0-.835-.104-1.366-.314-.533-.21-1.023-.313-1.471-.313-.47 0-.974.103-1.513.313-.54.21-.975.32-1.307.33-.506.021-1.01-.193-1.513-.643-.321-.27-.723-.732-1.204-1.387-.516-.7-.94-1.51-1.273-2.434C.179 10.535 0 9.568 0 8.632 0 7.56.24 6.635.723 5.86a4.162 4.162 0 011.514-1.474 4.191 4.191 0 012.046-.556c.402 0 .928.12 1.583.355.653.236 1.072.355 1.256.355.137 0 .603-.14 1.392-.418.746-.259 1.376-.366 1.892-.324 1.398.109 2.449.64 3.148 1.595-1.25.73-1.87 1.752-1.857 3.062.011 1.021.396 1.87 1.152 2.545.343.313.725.555 1.151.727-.092.258-.19.505-.293.742zM10.499.32c0 .8-.303 1.547-.908 2.239-.731.822-1.615 1.297-2.573 1.222A2.4 2.4 0 017 3.478c0-.768.347-1.59.964-2.263.308-.34.7-.623 1.175-.849.474-.222.922-.345 1.344-.366.012.107.017.214.017.32z"
                fill="currentcolor"
              ></path>
            </svg>
            iOS
          </a>
        </Link>
        <Link href={URL.PLAY_STORE}>
          <a className="button">
            <svg width="15" height="17">
              <path
                d="M1.043 5.374a.99.99 0 00-.728.3.978.978 0 00-.299.717v4.288c0 .286.1.528.3.728.199.199.441.299.727.299s.527-.1.723-.3a1 1 0 00.294-.727V6.39a.98.98 0 00-.299-.718.98.98 0 00-.718-.299zM9.3 1.525L10.006.22c.047-.086.03-.152-.05-.199-.086-.04-.153-.02-.199.06L9.04 1.396A4.892 4.892 0 007.036.977a4.9 4.9 0 00-2.004.42L4.314.08c-.047-.08-.113-.1-.2-.06-.08.047-.096.113-.05.2l.709 1.305a4.372 4.372 0 00-1.715 1.531 3.866 3.866 0 00-.638 2.149h9.222c0-.778-.213-1.494-.638-2.149A4.39 4.39 0 009.3 1.526zm-4.094 1.9a.374.374 0 01-.274.115.357.357 0 01-.27-.115.382.382 0 01-.109-.274c0-.106.037-.198.11-.274a.358.358 0 01.269-.115c.106 0 .198.038.274.115a.375.375 0 01.115.274.376.376 0 01-.115.274zm4.203 0a.358.358 0 01-.27.115.373.373 0 01-.274-.115.373.373 0 01-.114-.274c0-.106.038-.198.114-.274a.374.374 0 01.275-.115c.106 0 .195.038.269.115.073.076.11.168.11.274a.382.382 0 01-.11.274zM2.46 12.204c0 .306.105.565.318.778.213.212.472.319.778.319h.738l.01 2.263c0 .286.1.529.299.728.2.2.439.3.718.3a.99.99 0 00.727-.3c.2-.199.3-.442.3-.728v-2.263h1.375v2.263c0 .286.1.529.3.728.199.2.441.3.727.3a.99.99 0 00.728-.3c.2-.199.3-.442.3-.728v-2.263h.747c.3 0 .555-.106.768-.319.212-.213.319-.472.319-.778v-6.64H2.459v6.64zm10.568-6.83a.988.988 0 00-.718.294.979.979 0 00-.299.723v4.288c0 .286.1.528.3.728a.978.978 0 00.717.299.99.99 0 00.728-.3c.2-.199.3-.441.3-.727V6.39c0-.285-.1-.526-.3-.723a1 1 0 00-.728-.294z"
                fill="currentcolor"
              ></path>
            </svg>
            Android
          </a>
        </Link>
      </nav>
    </header>
  );
}

export default Header;

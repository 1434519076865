import { useEffect } from 'react';
import Head from 'next/head';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Header from '../components/Header';
import Footer from '../components/Footer';
// import firebase from '../utils/firebase';
import '../styles/globals.css';

function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      // firebase.analytics().logEvent(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <div>
      <Head>
        <link rel="icon" type="image/png" href="/images/icons/icon-72x72.png" />
        <link rel="manifest" href="/manifest.json" key="maniefest" />
        <link rel="canonical" href="https://lifeorganized.app/" />
        <title>Organized – Decluttering your way to an organized life</title>
        <meta
          name="description"
          content="Your one-stop solution to declutter and organize your home and life."
        />
      </Head>
      <Header />
      <Component {...pageProps} />
      <Footer />
    </div>
  );
}

export default App;
